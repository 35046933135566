import { FirebaseApp, initializeApp } from "firebase/app"

export function initialiseFirebaseApp(): FirebaseApp {
  const firebaseConfig = {
    apiKey: "AIzaSyB6kAtjh1LJhY3E_U57GPExrYbcn6nTUY0",
    appId: "1:506004542459:web:bfc3d7541425dc9a57b15e",
    authDomain: "virtuesjournal.com",
    measurementId: "G-407QZBJ3KF",
    messagingSenderId: "506004542459",
    projectId: "virtues-journal",
    storageBucket: "virtues-journal.appspot.com",
  }
  return initializeApp(firebaseConfig)
}
