import {
  GoogleAuthProvider,
  User,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth"
import { FirebaseApp } from "firebase/app"

export function startAuthenticationListener(app: FirebaseApp): void {
  const redirectIfLoggedOut = (user: User | null) => {
    if (user === null) {
      window.location.href = "./components/login/login.html"
    } else {
      currentUser = user
    }
  }
  const auth = getAuth(app)
  onAuthStateChanged(auth, redirectIfLoggedOut)
}

export function signInWithGoogle(
  callbackOnFailure: () => void,
  app: FirebaseApp,
): void {
  const auth = getAuth(app)
  const provider = new GoogleAuthProvider()
  const openMainPage = () => (window.location.href = "../../index.html")
  signInWithPopup(auth, provider).then(openMainPage).catch(callbackOnFailure)
}

export function getCurrentUserUID(): string | null {
  return currentUser != null ? currentUser.uid : null
}

export function getCurrentUserEmail(): string | null {
  return currentUser != null ? currentUser.email : null
}

let currentUser: User | null = null
